import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios' //vue-axios--api请求
import qs from 'qs' //vue-axios的post请求依赖的插件
import VueRouter from 'vue-router' //vue-router
import router from './router' //router配置文件index.js
import 'default-passive-events' // 解决 Added non-passive event listener to a scroll-blocking 'touchmove' event. Consider marking event han
import VueQuillEditor from 'vue-quill-editor' // __ **** 富文本编辑器
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'


import dayjs from "dayjs"
Vue.prototype.dayjs = dayjs;//可以全局使用dayjs

Vue.use(VueQuillEditor);

Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.config.productionTip = false

Vue.prototype._axios = axios //全局注册，使用方法为:this._axios
Vue.prototype._qs = qs //全局注册，使用方法为:this.$qs
// https://fz-property.static.fenzhisoft.com
// https://fz-property.obs.cn-southwest-2.myhuaweicloud.com/
Vue.prototype._IMAGE_URL = 'https://fz-property.static.fenzhisoft.com/' //全局注册图片地址，使用方法为:this._IMAGE_URL

/*是否是开发环境，开发环境则使用代理*/
// https://wuye.fenzhisoft.com/
// https://www.qinglang100.com/
if (process.env.NODE_ENV == 'development') { //开发环境
	axios.defaults.baseURL = '/api';
} else if (process.env.NODE_ENV == 'production') { //生产环境
	// axios.defaults.baseURL = 'https://www.qinglang100.com/';
	axios.defaults.baseURL = 'https://wuye.fenzhisoft.com/';
}

/*解决路由跳转原路由或者刷新出错*/
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch(err => err);
};
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export const postApi = (url, ...params) => {
	axios.defaults.headers.common["token"] = localStorage.getItem('token');
	// qs.stringify(...params)
	return new Promise((resolve, reject) => {
		axios.post(url, ...params)
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err.data)
			})
	});
};
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export const getApi = (url, ...params) => {
	axios.defaults.headers.common["token"] = localStorage.getItem('token');
	return new Promise((resolve, reject) => {
		axios.get(url, {
				params: params[0]
			})
			.then(res => {
				if (res.data.code == '-1') {
					console.log(res.data.code)
					router.replace({
						name: 'login',
						query: {}
					})
				} else {
					resolve(res.data);
				}
			})
			.catch(err => {
				reject(err.data)
			})
	});
}
Vue.prototype._postApi = postApi //挂载到Vue实例上面
Vue.prototype._getApi = getApi //挂载到Vue实例上面

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
