/*** 创建于：2019-11-22	 创建者：郑凯.*/
// 1.引入vue和vue-router并赋值给相应的Vue和Router
import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/*** 页面路径.
 *	index 首页
 * 	login 登录
 */

const router = new Router({
	linkActiveClass: 'active', // 指定路由选中时的样式类名
	hashbang: true, // 将路径格式化为#!开头
	// base: './',
	mode: 'history',
	history: true, // 启用HTML5 history模式，可以使用pushState和replaceState来管理记录
	routes: [{
		path: '/',
		name: 'main',
		components: {
			main: function() {
				return import('@/components/main');
			}
		},
	},{
		path: '/index',
		name: 'index',
		components: {
			main: function() {
				return import('@/components/index');
			}
		},
		children: [{
			path: '/homePage',
			name: 'homePage',
			components: {
				mainContainer: function() {
					return import('@/components/home_page');
				}
			},
		}, {
			path: '/basicSettings',
			name: 'basicSettings',
			components: {
				mainContainer: function() {
					//基础设置
					return import('@/components/basic_settings/index');
				}
			},
			children: [{
				path: '/community_settings',
				name: 'community_settings',
				components: {
					conContainer: function() {
						//小区设置
						return import('@/components/community_settings/index');
					}
				}
			},{
				path: '/house_manage',
				name: 'house_manage',
				components: {
					conContainer: function() {
						//房产管理
						return import('@/components/house_manage/index');
					}
				}
			},{
				path: '/owner_manage',
				name: 'owner_manage',
				components: {
					conContainer: function() {
						//业主管理
						return import('@/components/owner_manage/index');
					}
				}
			},{
				path: '/Property_services',
				name: 'Property_services',
				components: {
					conContainer: function() {
						//物业服务
						return import('@/components/basic_settings/Property_services/index');
					}
				}
			},{
				path: '/meter_manage',
				name: 'meter_manage',
				components: {
					conContainer: function() {
						//仪表管理
						return import('@/components/basic_settings/meter_manage/index');
					}
				}
			}]
		}, {
			path: '/payment_management',
			name: 'payment_management',
			components: {
				mainContainer: function() {
					//收费管理
					return import('@/components/payment_management/index');
				}
			},
			children: [{
				path: '/Collect_fees_service',
				name: 'Collect_fees_service',
				components: {
					conContainer: function() {
						//收费项目
						return import('@/components/Collect_fees_service/index');
					}
				}
			},{
				path: '/Cof_laydown_criterion',
				name: 'Cof_laydown_criterion',
				components: {
					conContainer: function() {
						//制定收费标准
						return import('@/components/Cof_laydown_criterion/index');
					}
				}
			},{
				path: '/Cof_laydown_standard_bind',
				name: 'Cof_laydown_standard_bind',
				components: {
					conContainer: function() {
						//收费标准绑定
						return import('@/components/Cof_laydown_standard_bind/index');
					}
				}
			},{
				path: '/Col_cost_generation',
				name: 'Col_cost_generation',
				components: {
					conContainer: function() {
						//费用计算
						return import('@/components/Col_cost_generation/index');
					}
				}
			},{
				path: '/Cof_cost_list',
				name: 'Cof_cost_list',
				components: {
					conContainer: function() {
						//费用计算
						return import('@/components/Cof_cost_list/index');
					}
				}
			},{
				path: '/Discount_set',
				name: 'Discount_set',
				components: {
					conContainer: function() {
						//优惠设置
						return import('@/components/Discount_set/index');
					}
				}
			},{
				path: '/Col_yingshoutiaozheng',
				name: 'Col_yingshoutiaozheng',
				components: {
					conContainer: function() {
						//应收调整
						return import('@/components/Col_yingshoutiaozheng/index');
					}
				}
			},{
				path: '/Cof_order_query',
				name: 'Cof_order_query',
				components: {
					conContainer: function() {
						//缴费查询
						return import('@/components/Cof_order_query/index');
					}
				}
			},{
				path: '/charge_note',
				name: 'charge_note',
				components: {
					conContainer: function() {
						//缴费通知单
						return import('@/components/charge_note/index');
					}
				}
			}]
		}, {
			path: '/personnel_management',
			name: 'personnel_management',
			components: {
				mainContainer: function() {
					//人员管理
					return import('@/components/personnel_management/index');
				}
			},
			children: [{
				path: '/role_setting',
				name: 'role_setting',
				components: {
					conContainer: function() {
						//角色设置
						return import('@/components/role_setting/index');
					}
				}
			}, {
				path: '/account_settings',
				name: 'account_settings',
				components: {
					conContainer: function() {
						//角账号设置
						return import('@/components/account_settings/index');
					}
				}
			}, {
				path: '/account_xcx',
				name: 'account_xcx',
				components: {
					conContainer: function() {
						//员工小程序端
						return import('@/components/account_xcx/index');
					}
				}
			}]
		}, {
			path: '/work_management',
			name: 'work_management',
			components: {
				mainContainer: function() {
					//工作管理
					return import('@/components/work_management/index');
				}
			},
			children:[{
				path: '/notice_manage',
				name: 'notice_manage',
				components: {
					conContainer: function() {
						//通知管理
						return import('@/components/notice_manage/index');
					}
				}
			},{
				path: '/newspaper',
				name: 'newspaper',
				components: {
					conContainer: function() {
						//报事管理
						return import('@/components/work_management/newspaper/left_tree');
					}
				}
			},{
				path: '/visitRecord',
				name: 'visitRecord',
				components: {
					conContainer: function() {
						//报事管理
						return import('@/components/work_management/visitRecord/left_tree');
					}
				}
			}]
		}, {
			path: '/support_services',
			name: 'support_services',
			components: {
				mainContainer: function() {
					//支持服务
					return import('@/components/support_services/index');
				}
			},
			children: [{
				path: '/community_payment',
				name: 'community_payment',
				components: {
					conContainer: function() {
						//小区收费
						return import('@/components/community_payment/index');
					}
				}
			}]
		}, {
			path: '/data_manage',
			name: 'data_manage',
			components: {
				mainContainer: function() {
					// 数据管理
					return import('@/components/data_manage/index');
				}
			},
			children: [{
				path: '/dataout',
				name: 'dataout',
				components: {
					conContainer: function() {
						return import('@/components/data_manage/dataout/dataout');
					}
				}
			},{
				path: '/datain',
				name: 'datain',
				components: {
					conContainer: function() {
						return import('@/components/data_manage/datain/datain');
					}
				}
			},{
				//收费汇总查询
				path: '/charge_query',
				name: 'charge_query',
				components: {
					conContainer: function() {
						return import('@/components/data_manage/charge_total_query/index');
					}
				}
			},{
				//收费率查询
				path: '/charge_rate_query',
				name: 'charge_rate_query',
				components: {
					conContainer: function() {
						return import('@/components/data_manage/charge_rate_query/index');
					}
				}
			},{
				//预收分摊查询
				path: '/advance_allocation_query',
				name: 'advance_allocation_query',
				components: {
					conContainer: function() {
						return import('@/components/data_manage/advance_allocation_query/index');
					}
				}
			},{
				//欠费汇总查询
				path: '/arrearage_query',
				name: 'arrearage_query',
				components: {
					conContainer: function() {
						return import('@/components/data_manage/arrearage_query/index');
					}
				}
			}]
		}]
	}, {
		path: '/login',
		name: 'login',
		components: {
			main: function() {
				return import('@/components/login');
			}
		}
	}]
});
export default router
