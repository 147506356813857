<style>
	webview{display:block;border:none;width:80vh;height:95vw;}
	#app {font-family: Avenir, Helvetica, Arial, sans-serif;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;height: 100%;overflow: hidden;}
	html,
	body {margin: 0;padding: 0;font-size: 1vw;width: 100%;height: 100%;}
	div,
	table {box-sizing: border-box;}
	/* 菜单 */
	.left_nav_box ul.el-menu.el-menu--inline {background-color: #67CD80;}
	.left_nav_box ul.el-menu .el-menu-item:focus,
	.left_nav_box ul.el-menu .el-menu-item:hover {background-color: rgba(255, 255, 255, 0.2);}
	.left_nav_box ul.el-menu .el-menu-item.is-active {background-color: #FFF;font-weight: bold;}
	/* -----------
	数据数菜单 
	----------- */
	.con_page_container {width: 100%;height: 100%;display: flex;}
	.left_tree_box {width: 14%;overflow: auto;background-color: #AFF6C0;padding-right: 5px;}
	.main_container {flex: 1;width: 86%;}
	.ch_temp_box {width: 100%;height: 100%;}
	/* tree */
	.left_tree_box .el-tree {background-color: #AFF6C0;}
	.left_tree_box .el-tree-node__label {font-size: 1rem;}
	.left_tree_box .el-tree-node {padding: 3px 0;}
	.left_tree_box .el-tree-node__content:hover {background-color: rgba(0, 0, 0, 0);}
	.left_tree_box .el-tree-node:focus>.el-tree-node__content {background-color: rgba(0, 0, 0, 0);}
	.left_tree_box .el-tree-node.is-current.is-focusable:focus>.el-tree-node__content {background-color: #fff;}
	.left_tree_box .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{background-color: #fff;font-weight: bold;}
	/* 弹窗 */
	.el-dialog.my_dialog {max-width: 100%;max-height: 100%;overflow: hidden;display: flex;flex-direction: column;margin: auto;}
	.el-dialog.my_dialog .el-dialog__header {padding: 10px !important;}
	.el-dialog.my_dialog .el-dialog__body {padding: 10px 10px 10px 10px !important;flex: 1;overflow: hidden;}
	/* 表格 */
	body .el-table th.gutter {
	    display: table-cell !important
	}
	
	.dialog_data_str .el-breadcrumb__inner{font-weight: bold!important;font-size: 1.2rem;}
	
	.el-dialog__wrapper{display: flex;align-items: center;}
</style>

<template>
	<div id="app">
		
		<router-view name="main"></router-view>
	</div>
</template>

<script>
	export default {
		name: 'App',
		components: {

		}
	}
</script>
